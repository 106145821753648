import {
  ApolloProvider,
  useQuery as useQueryApollo
} from '@apollo/client/react';
import { DocumentNode, OperationVariables } from '@apollo/client/core';
import { GraphQLService } from './services/GraphQLService/GraphQLService';
import React, { ReactNode } from 'react';

const useQuery = (
  query: DocumentNode,
  options?: { variables: OperationVariables }
) => {
  const result = useQueryApollo(query, options);
  return result;
};

const createGraphQLProvider = (react: typeof React) => {
  const GraphQLProvider = ({
    children,
    client
  }: {
    children: ReactNode;
    client: GraphQLService;
  }) => {
    return react.createElement(
      ApolloProvider,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { client: (client as any)._apolloClient, children },
      children
    );
  };
  return GraphQLProvider;
};

export { createGraphQLProvider, useQuery };
